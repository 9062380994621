import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { createNewCaseStudy } from "../../../../api/caseStudies";
import { AddMediaComponent } from "../../../components/media/AddMediaComponent";
import { CollectionCreate } from "../../../layouts/CollectionCreate";
import TrashIcon from "../../../components/assets/TrashIcon";
import ReorderIcon from "../../../components/assets/ReorderIcon";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AddPdfComponent } from "../../../components/media/AddPdfComponent";
import PlusIcon from "../../../components/assets/PlusIcon";
import { useAlert } from "react-alert";

function CreateCaseStudy() {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { user } = useAuth0();
  const [selectedImage, setSelectedImage] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [input, setInput] = useState({
    title: "",
    url: "",
    company: "",
  });
  const [selected, setSelected] = useState([]);
  const [documentsArray, setDocumentsArray] = useState([]);

  // handle input change
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // create new case study
  const { mutate } = useMutation(createNewCaseStudy, {
    onSuccess: (newCaseStudy) => {
      const data = newCaseStudy.data.CaseStudy;
      queryClient.setQueryData("getCaseStudies", (prev) => [...prev, data]);
      alert.show("Your case study has successfully been created.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // account for bottom placement of list item while being dragged
  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(documentsArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDocumentsArray(items);
  }

  // add selected documents to array
  useEffect(() => {
    selected.forEach((sel) => {
      setDocumentsArray((prev) => [...prev, sel]);
    });
  }, [selected]);

  // remove documents from array
  function handleDeleteSelect(idx) {
    setDocumentsArray(documentsArray.filter((item, i) => i !== idx));
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { title: "", url: "" }]);
  };

  function handleSubmit(e) {
    e.preventDefault();
    mutate({
      title: input.title,
      image: selectedImage,
      links: inputList,
      user: user.sub,
      documents: documentsArray,
    });
    setSelectedImage("");
    setInput({
      title: "",
      url: "",
      company: "",
    });
    setInputList([{ title: "", url: "" }]);
    setDocumentsArray([]);
  }

  return (
    <CollectionCreate title="Create New Swag">
      <AddMediaComponent
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        message="Recommended image size is 350px x 525px"
        showImages={true}
      />
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Title"
              placeholder="Enter Title"
              value={input.title}
              onChange={handleInput}
              name="title"
              required
            />
            <small className="fst-italic text-primary">* Required</small>
          </Col>
        </Row>
        <Row className="py-3">
          <h4 className="mb-0">Add Documents</h4>
        </Row>
        <Row className="editor-module mb-3">
          <AddPdfComponent
            setSelectedDocument={setSelected}
            selectedDocument={selected}
            multiple={true}
            showPDFs={false}
          />
          <Col xs={12}>
            <div className="editor-module-selected-list">
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="documents">
                  {(provided) => (
                    <ul
                      className="editor-module-selected-list-inner"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {documentsArray &&
                        documentsArray.map((doc, idx) => (
                          <Draggable
                            key={idx}
                            draggableId={`documents-inner_${idx}`}
                            index={idx}
                          >
                            {(provided) => (
                              <li
                                className="editor-module-selected-list-inner-item"
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="editor-module-selected-list-item-name">
                                    <p>{doc.fileName}</p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button
                                    className="transparent me-3"
                                    onClick={() => handleDeleteSelect(idx)}
                                  >
                                    <TrashIcon height="16px" width="16px" />
                                  </button>
                                  <div
                                    className="reorder-icon"
                                    {...provided.dragHandleProps}
                                  >
                                    <ReorderIcon height="16px" width="16px" />
                                  </div>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Col>
        </Row>
        <Row className="py-3">
          <h4 className="mb-0">Add Links</h4>
        </Row>
        {inputList.map((x, i) => (
          <Row
            key={`additional-contacts-${i}`}
            className="mb-3 align-items-start"
          >
            <Col lg={5}>
              <Form.Control
                name="title"
                className="my-2"
                placeholder="Link Title"
                value={x.title}
                onChange={(e) => handleInputChange(e, i)}
                required={x.url}
              />
              {(x.title || x.url) && inputList.length - 1 === i && (
                <small className="fst-italic text-primary">* Required</small>
              )}
            </Col>
            <Col lg={5}>
              <Form.Control
                name="url"
                className="my-2"
                placeholder="Link Address"
                value={x.url}
                onChange={(e) => handleInputChange(e, i)}
                required={x.title}
              />
              {(x.title || x.url) && inputList.length - 1 === i && (
                <small className="fst-italic text-primary">{`* Required (URL must include http:// or https://
                )`}</small>
              )}
            </Col>
            <Col lg={2} className="d-flex">
              {inputList.length !== 0 && (
                <button
                  type="button"
                  className="transparent mx-2 py-3"
                  onClick={() => handleRemoveClick(i)}
                >
                  <TrashIcon height="16px" width="16px" />
                </button>
              )}
              {inputList.length - 1 === i && (
                <button
                  type="button"
                  className="transparent mx-2 py-3"
                  onClick={handleAddClick}
                >
                  <PlusIcon height="16px" width="16px" />
                </button>
              )}
            </Col>
          </Row>
        ))}
        {!inputList.length && (
          <Row className="mb-3 justify-content-end">
            <Col lg={12}>
              <Button variant="outline-dark" onClick={handleAddClick}>
                Add Link
              </Button>
            </Col>
          </Row>
        )}
        <div className="mt-5">
          <Button variant="primary" size="lg" type="submit">
            Add Swag
          </Button>
        </div>
      </Form>
    </CollectionCreate>
  );
}

export default CreateCaseStudy;

// imageLabel="Recommended Image size is 350px x 525px"
