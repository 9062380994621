import React, { useState } from "react";
import CreateCaseStudy from "./modules/CreateCaseStudy";
import ListCaseStudies from "./modules/ListCaseStudies";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import Papa from "papaparse";
import { Helmet } from "../../layouts/Helmet";
import {
  deleteCaseStudy,
  getCaseStudies,
  importCaseStudies,
} from "../../../api/caseStudies";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CaseStudyImportModal } from "../../components/import-modals/CaseStudyImportModal";
import caseStudySampleCSV from "../../../assets/sample-csv/case-study-sample.csv";
import { useAlert } from "react-alert";
import { ConfirmationModal } from "../../components/ConfirmationModal";

function CaseStudies() {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const alert = useAlert();
  const [csvData, setCsvData] = useState([]);
  const queryClient = useQueryClient();
  const [deleteCaseObj, setDeleteCaseObj] = useState({
    id: "",
    title: "",
  });

  // bulk import services
  const {
    mutate: bulkMutate,
    isError: bulkError,
    isLoading: bulkLoading,
  } = useMutation(importCaseStudies, {
    onSuccess: (newCaseStudy) => {
      const data = newCaseStudy.data.CaseStudy;
      queryClient.setQueryData("getCaseStudies", (prev) => [...prev, ...data]);
      alert.show("Your swag have successfully been imported.", {
        timeout: 3000,
        type: "success",
      });
      setShow(false);
    },
    onError: (response) => {
      alert.show(response.message, {
        type: "error",
      });
    },
  });

  // Delete service
  const { mutate } = useMutation(
    ["deleteCaseStudy", deleteCaseObj.id],
    () => deleteCaseStudy(deleteCaseObj.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getCaseStudies");
      },
    }
  );

  // Get Case Studies
  const { data, isLoading, isError } = useQuery(
    "getCaseStudies",
    getCaseStudies,
    {
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // handle delete mutation
  function handleDeleteMutation() {
    mutate();
  }

  // parse csv data
  function handleCsv(e) {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        // skipEmptyLines: true,
        complete: function (results) {
          uploadFile(results);
        },
      });
    }
  }

  // handle file upload
  function uploadFile(results) {
    if (results.errors.length > 0) {
      alert(results.errors[0].message);
    } else {
      setCsvData(results.data);
      setShow(true);
    }
  }

  return (
    <ScreenWrapper
      handleCsv={handleCsv}
      importIcon={true}
      screenTitle="Swag"
      show={show}
      csv={caseStudySampleCSV}
      csvRequiredStatement="<p><strong>Hey, listen!</strong> You gotta remember a few things when you're importing swag:</p><ul><li>A unique name for your swag, to start.</li><li>You also have to upload documents manually for each case study you import. No shortcuts!</li><li>Also, you gotta separate link titles and link URLs with the '|' character (i.e. press 'Shift' and '\' together)</li><ul>"
    >
      <Helmet metaTitle="Swag | Spark | RebelWare" />

      <CreateCaseStudy />
      <ListCaseStudies
        data={data}
        setShowModal={setShowModal}
        isLoading={isLoading}
        isError={isError}
        setDeleteCaseObj={setDeleteCaseObj}
      />
      <CaseStudyImportModal
        show={show}
        onHide={() => setShow(false)}
        data={csvData}
        isLoading={bulkLoading}
        isError={bulkError}
        mutate={bulkMutate}
      />
      <ConfirmationModal
        id={deleteCaseObj.id}
        show={showModal}
        message={`Are you sure you want to delete the following swag?`}
        item={deleteCaseObj.title}
        onHide={() => setShowModal(false)}
        mutate={handleDeleteMutation}
      />
    </ScreenWrapper>
  );
}

export default CaseStudies;
