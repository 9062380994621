import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { CollectionList } from "../../../layouts/CollectionList";
import { CaseStudyListItem } from "./CaseStudyListItem";

function ListCaseStudies({
  setDeleteCaseObj,
  setShowModal,
  data,
  isLoading,
  isError,
}) {
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return null;
  } else {
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return (
      <CollectionList
        title="View / Edit Swag"
        loading={isLoading}
        error={isError}
      >
        {data.map((caseStudy) => (
          <Fragment key={caseStudy._id}>
            <CaseStudyListItem
              caseStudy={caseStudy}
              setDeleteCaseObj={setDeleteCaseObj}
              setShowModal={setShowModal}
            />
          </Fragment>
        ))}
      </CollectionList>
    );
  }
}

export default ListCaseStudies;
