import { CaseStudiesTitle } from "../module-titles/CaseStudiesTitle";
import { ContactInformationTitle } from "../module-titles/ContactInformationTitle";
import { DocumentsTitle } from "../module-titles/DocumentsTitle";
import { ImagesTitle } from "../module-titles/ImagesTitle";
import { MeetTheTeamTitle } from "../module-titles/MeetTheTeamTitle";
import { ServicesTitle } from "../module-titles/ServicesTitle";
import { TestimonialsTitle } from "../module-titles/TestimonialsTitle";
import { TextTitle } from "../module-titles/TextTitle";
import { VideosTitle } from "../module-titles/VideosTitle";
import { CaseStudies } from "./CaseStudies";
import { ContactInformation } from "./ContactInformation";
import { Documents } from "./Documents";
import { Images } from "./Images";
import { MeetTheTeam } from "./MeetTheTeam";
import { Services } from "./Services";
import { Testimonials } from "./Testimonials";
import { Text } from "./Text";
import { Videos } from "./Videos";

export const frontendModules = [
  {
    id: "3",
    name: "Team Members",
    component: MeetTheTeam,
  },
  {
    id: "4",
    name: "Contact Information",
    component: ContactInformation,
  },
  {
    id: "5",
    name: "Documents",
    component: Documents,
  },
  {
    id: "6",
    name: "Swag",
    component: CaseStudies,
  },
  {
    id: "7",
    name: "Videos",
    component: Videos,
  },
  {
    id: "8",
    name: "Testimonials",
    component: Testimonials,
  },
  {
    id: "9",
    name: "Services",
    component: Services,
  },
  {
    id: "10",
    name: "Images",
    component: Images,
  },
  {
    id: "11",
    name: "Text",
    component: Text,
  },
];

export const frontendTitles = [
  {
    id: "3",
    name: "Team Members",
    component: MeetTheTeamTitle,
  },
  {
    id: "4",
    name: "Contact Information",
    component: ContactInformationTitle,
  },
  {
    id: "5",
    name: "Documents",
    component: DocumentsTitle,
  },
  {
    id: "6",
    name: "Swag",
    component: CaseStudiesTitle,
  },
  {
    id: "7",
    name: "Videos",
    component: VideosTitle,
  },
  {
    id: "8",
    name: "Testimonials",
    component: TestimonialsTitle,
  },
  {
    id: "9",
    name: "Services",
    component: ServicesTitle,
  },
  {
    id: "10",
    name: "Images",
    component: ImagesTitle,
  },
  {
    id: "11",
    name: "Text",
    component: TextTitle,
  },
];
