import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";
import DocumentIcon from "../../../../components/assets/DocumentIcon";
import GlobeIcon from "../../../../components/assets/GlobeIcon";
import placeholder from "../../../../../assets/images/500x500-image-placeholder.jpg";
import { DocumentModal } from "../layouts/DocumentModal";

export const CaseStudies = ({ id, data, title, index }) => {
  const [modalShow, setModalShow] = useState(false);
  const [document, setDocument] = useState();
  function handleDocument(doc) {
    setDocument(doc);
    setModalShow(true);
  }

  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].caseStudies.length > 0 && (
          <ComponentWrapper id={id} title={title}>
            <Row>
              {data[index].caseStudies.map((caseStudy, idx) => (
                <Col key={`cs-${idx}`} lg={6} className="case-study-col">
                  <figure>
                    <img
                      src={
                        caseStudy.image.length
                          ? caseStudy.image[0].fileUrl[2]
                          : placeholder
                      }
                      width="100%"
                      height="auto"
                      alt={
                        caseStudy.image.length
                          ? caseStudy.fileAlt
                          : "placeholder-image"
                      }
                    />
                  </figure>
                  <h3 className="case-study-title">{caseStudy.title}</h3>
                  {caseStudy.documens &&
                    caseStudy.documents.map((doc, idx) => (
                      <div
                        className="case-studies-documents"
                        key={`cs-doc-${idx}`}
                      >
                        <button
                          className="transparent"
                          onClick={() => handleDocument(doc)}
                        >
                          {doc.fileAlt ? doc.fileAlt : "View Document"}
                        </button>
                      </div>
                    ))}
                  {caseStudy.links &&
                    caseStudy.links.map(
                      (link, idx) =>
                        link[0].url && (
                          <div
                            className="case-studies-documents"
                            key={`cs-link-${idx}`}
                          >
                            <a
                              href={link[0].url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link[0].title ? link[0].title : "View Link"}
                            </a>
                          </div>
                        )
                    )}
                </Col>
              ))}
            </Row>
            <DocumentModal
              document={document}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </ComponentWrapper>
        )}
      </>
    );
  }
};
